import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ImageCarousel from "../components/img/ImageCarousel"
import LogoStream from "../components/img/LogoStream"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import PubSearch from "../components/PubMap/Search"

const BookTablePage = () => {
  const images = [
    {
      src: "/banners/landing-banner-bookings.jpg",
      alt: "Book a table",
      link: null,
    },
  ]

  return (
    <Layout>
      <Seo
        title={`The Dining Out Gift Card | Valid in over 1600 Restaurants, Pubs & Bars`}
        description={`Valid in restaurants across the UK including Miller & Carter, Harvester, Toby Carvery & more. The perfect gift for birthdays, thank yous, rewards and gifts.`}
      />

      <ImageCarousel images={images} />

      <LogoStream />

      <div className="content-wrapper">
        <div className="main-with-sidebar">
          <h1>Book Online at over 1600 restaurants, pubs & bars</h1>

          <p>
            You can spend your Dining Out Gift Card at any one of our 1600
            locations, across the UK.
          </p>

          <p>
            Search for the location you would like using the form below and
            discover the restaurants, bars & pubs in your area. You can then
            reserve a table at your favourite, whether it's to dine or just for
            drinks.
          </p>

          <h2>Group Booking Enquiries</h2>

          <p>
            Many of our venues make the perfect place for special occasions, and
            we'll always endeavour to accommodate larger group bookings. To make
            your occasion truly memorable, we request that for parties of seven
            or more you contact the bar, pub or restaurant you're interested in
            visiting to make a booking enquiry. You can search for their contact
            details below.
          </p>
        </div>

        <div className="sidebar">
          <div className="box">
            <a
              href="https://diningout.cashstar.com/"
              rel="noreferrer"
              target="_blank"
              className="w-full"
            >
              <StaticImage
                src="../../static/boxes/buy-now-2.png"
                alt="Dining Out Card"
                className="w-full"
              />
            </a>
          </div>
          <div className="box">
            <a
              href="https://diningout-biz.cashstar.com/"
              rel="noreferrer"
              target="_blank"
              className="w-full"
            >
              <StaticImage
                src="../../static/boxes/corporate-gift-cards.png"
                alt="Corporate Gifts"
                className="w-full"
              />
            </a>
          </div>
        </div>
      </div>

      <PubSearch />
    </Layout>
  )
}

export default BookTablePage
